import React from 'react';
import './service.css';

const Service = ({ dataAos }) => {
  return (
    <div className="sps-service section_padding">
      <div className="sps-service-card1" data-aos={dataAos}>
        <h3>Product Design (UI/UX)</h3>
        <p>
          Utilize widespread creative skills to create digital experiences that
          are focused on the client.
        </p>
      </div>
      <div className="sps-service-card2" data-aos={dataAos}>
        <h3>Software Development</h3>
        <p>
          Create error-free software products and ship them. Work together to
          deliver dependable and effective software products.
        </p>
      </div>
      <div className="sps-service-card3" data-aos={dataAos}>
        <h3>Digital Marketing</h3>
        <p>
          We help your business scale, grow, and reach new heights with
          effectively executed digital marketing strategies and solutions.
        </p>
      </div>
    </div>
  );
};

export default Service;
