import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Navbar } from '../../Components';
import './aboutus.css';
import AboutHeader from '../../assets/About/AboutHeader.png';
import { Footer, Motto } from '../../Container';
import valuesicon from '../../assets/icons/values.svg';
import missionicon from '../../assets/icons/mission.svg';
import visionicon from '../../assets/icons/vision.svg';

const Aboutus = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, easing: 'ease-in-out-sine' });
  }, []);

  return (
    <div>
      <Navbar />
      <div className="About-header">
        <h1 data-aos="fade-up">
          One solution at a time, we're transforming the world.
        </h1>

        <div className="About-header-p" data-aos="fade-up">
          <p>
            We adore products. We think that design is an interactive process,
            and that by working together, we can create amazing products.
          </p>
        </div>
      </div>
      <div className="About-body section_padding">
        <div className="About-body-img" data-aos="fade-up">
          <img src={AboutHeader} alt="" />
        </div>
        <div className="About-body-content" data-aos="fade-up">
          <h2>We develop concepts into profitable products.</h2>
          <p>
            Spaceserve Technologies Limited is a software solutions company that
            designs industry-specific as well as custom-made digital solutions
            tailored to meet the needs of the customers with its effort focused
            in two service areas: database/app development and clients’ support.
            Our primary focus is to delivering to businesses and individuals
            cutting edge solutions that address their biggest pain points and
            ensure their success.
          </p>
        </div>
      </div>
      <Motto
        dataAos="fade-up"
        icon1={visionicon}
        icon2={missionicon}
        icon3={valuesicon}
        Header1="Our Mission"
        Body1="Empower individuals and organizations to achieve more by building world class digital platforms and productivity solutions."
        Header2="Our Vision"
        Body2="To be the leading provider of digitally transforming software solutions in Africa."
        Header3="Our Core Values"
        Body3="Innovation, Teamwork, Customer Success, Professionalism, Integrity,"
      />

      <Footer dataAos="fade-up" />
    </div>
  );
};

export default Aboutus;
