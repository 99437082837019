import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home, Aboutus, Contact, Services } from './Pages';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="aboutus" element={<Aboutus />} />
        <Route path="contact" element={<Contact />} />
        <Route path="services" element={<Services />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </div>
  );
};

export default App;
