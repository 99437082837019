import React from 'react';
import './motto.css';

const Motto = ({
  title,
  icon1,
  Header1,
  Body1,
  icon2,
  Header2,
  Body2,
  icon3,
  Header3,
  Body3,
  dataAos,
}) => {
  return (
    <div>
      <div className="sps-motto section_padding">
        <div className="sps-motto-heading" data-aos={dataAos}>
          <h2>{title}</h2>
        </div>
        <div className="sps-motto-content">
          <div className="sps-motto-content1" data-aos={dataAos}>
            <img src={icon2} alt="" />
            <h6>{Header1}</h6>
            <p>{Body1}</p>
          </div>
          <div className="sps-motto-content2" data-aos={dataAos}>
            <img src={icon1} alt="" />
            <h6>{Header2}</h6>
            <p>{Body2}</p>
          </div>
          <div className="sps-motto-content3" data-aos={dataAos}>
            <img src={icon3} alt="" />
            <h6>{Header3}</h6>
            <p>{Body3}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Motto;
