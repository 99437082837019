import React from 'react';
import { Link } from 'react-router-dom';
import './about.css';
import homeabout from '../../assets/home-aboutus.jpg';
const About = ({ dataAos }) => {
  return (
    <div>
      <div className="sps-aboutus section_padding">
        <div className="sps-aboutus-img" data-aos={dataAos}>
          <img src={homeabout} alt="About Us" />
        </div>
        <div className="sps-aboutus-details" data-aos={dataAos}>
          <h2>About Us</h2>
          <p>
            {' '}
            <b>Spaceserve Technologies Limited </b>is a software solutions
            company that designs industry-specific as well as custom-made
            digital solutions tailored to meet the needs of the customers with
            its effort focused in two service areas: database/app development
            and clients’ support. Our primary focus is to delivering to
            businesses and individuals cutting edge solutions that address their
            biggest pain points and ensure their success.
          </p>
        </div>
      </div>
      <div className="sps-about section_padding" data-aos={dataAos}>
        <div className="sps-about-heading">
          <h2>What we do</h2>
        </div>
        <div className="sps-about-body">
          <p>
            We create ideal digital solutions for businesses Our services border
            around solving challenges businesses inevitably face. From brand
            design, to business development, to product and web development, we
            are driven by the desire to help businesses reach their full
            potential.
          </p>
        </div>
        <div className="sps-about-button">
          <Link to="/services">
            <p>Explore our services</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default About;
