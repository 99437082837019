import React from 'react';
import './header.css';
import { Link } from 'react-router-dom';

const Header = ({ dataAos }) => {
  return (
    <div className="sps-header section_padding">
      <div className="sps-header-content" data-aos={dataAos}>
        <h1>Building world class digital platforms and solutions</h1>
        <p>
          We are a dynamic team of Experts, that deliver high quality technology
          solutions to business challenges and transform ideas into viable
          products
        </p>
        <div className="sps-header-content-button">
          <Link to="/contact">
            <button>Find out how</button>
          </Link>
        </div>
      </div>
      <div className="sps-header-image" data-aos={dataAos}>
        <img src={require('../../assets/homeimg.png')} alt="loading..." />
      </div>
    </div>
  );
};

export default Header;
