import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Navbar, Header } from '../../Components';
import './home.css';
import { Footer, Motto, About, Service } from '../../Container';
import icon2 from '../../assets/icons/icon2.svg';
import icon1 from '../../assets/icons/icon1.svg';
import icon3 from '../../assets/icons/icon3.svg';

const Home = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, easing: 'ease-in-out-sine' });
  }, []);

  return (
    <div className="App">
      <div className="">
        <Navbar />
        <Header dataAos="fade-up" />
      </div>

      <div>
        <About dataAos="fade-up" />
        <Service dataAos="fade-up" />
        <Motto
          dataAos="fade-up"
          title="We are not just another tech start-up we are so much more. Watch us win!"
          icon1={icon1}
          icon2={icon2}
          icon3={icon3}
          Header1="Energetic Team"
          Body1="Our success is reliant on the brilliance and energy of our team, who combine a passion for solving problems with a hefty dose of business know-how to deliver excellent work in return."
          Header2="Personalized solutions"
          Body2="Templates? No way. We customize plans and curate strategies tailored to fit the unique goals and needs of each business we work with."
          Header3="Timely delivery"
          Body3="Integrity matters to us and so we always ensure to keep to our word, especially with deadlines. We pride ourselves in consistently delivering work that exceeds client expectations and make them come back"
        />
        <Footer dataAos="fade-up" />
      </div>
    </div>
  );
};

export default Home;
