import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.css';
import logo from '../../assets/logo.png';

const Menu = () => (
  <>
    <p>
      <NavLink to="/" end>
        Home
      </NavLink>
    </p>
    <p>
      <NavLink to="/services">Services</NavLink>
    </p>
    <p>
      <NavLink to="/aboutus">About Us</NavLink>
    </p>
  </>
);
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="sps-navbar">
      <div className="sps-navbar-link">
        <div className="sps-navbar-link-logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="sps-navbar-link-container">
          <Menu />
        </div>
      </div>
      <div className="sps-navbar-contact">
        <Link to="/contact">
          <button type="button">Request Services</button>
        </Link>
      </div>
      <div className="sps-navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#105F78"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#105F78"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="sps-navbar-menu-container scale-up-center">
            <div className="sps-navbar-menu-container-links">
              <Menu />
              <div className="sps-navbar-menu-contact">
                <Link to="/contact">
                  <button type="button">Contact</button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
