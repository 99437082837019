import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { Navbar } from '../../Components';
import './contact.css';
import { Footer } from '../../Container';

const Contact = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, easing: 'ease-in-out-sine' });
  }, []);

  return (
    <div>
      <Navbar />
      <div className="Contact section_padding">
        <div className="Contact-form">
          <div className="">
            <h2 data-aos="fade-up">REACH US NOW!!!</h2>
            <div className="Form" data-aos="fade-up">
              <ToastContainer />
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  service_type: '',
                  request_type: '',
                  brief: '',
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.name) {
                    errors.name = 'Name is Required';
                  }
                  if (!values.email) {
                    errors.email = 'Email is Required';
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = 'Invalid email address';
                  }
                  if (!values.service_type) {
                    errors.service_type = 'Please select a Service type';
                  }
                  if (!values.request_type) {
                    errors.request_type = 'Please select a Request type';
                  }
                  if (!values.brief) {
                    errors.brief =
                      'Tell us what you are looking to build with Spaceserve.';
                  }

                  return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  fetch('http://127.0.0.1:8000/api/users', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'text/plain',
                    },
                    body: JSON.stringify(values),
                  })
                    .then((response) => response.json())
                    .then((data) => {
                      console.log(data);
                      toast.success('Form submitted successfully');
                      resetForm({ value: '' });
                      setSubmitting(false);
                    })
                    .catch((error) => {
                      console.log(error);
                      toast.error(
                        'An error occurred while submitting, Please try again'
                      );
                      setSubmitting(false);
                    });
                }}
              >
                {({ isSubmitting, status }) => (
                  <Form>
                    {/* {submitting && <SuccessScreen message={status} />} */}
                    <div className="Contact-field">
                      <div className="Contact-input">
                        <Field
                          type="text"
                          name="name"
                          placeholder="Your name"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="Contact-field">
                      <div className="Contact-input">
                        <Field
                          type="email"
                          name="email"
                          placeholder="Your email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="Contact-field">
                      <Field component="select" name="service_type">
                        <option value="" disabled selected hidden>
                          Service type
                        </option>
                        <option value="Web/App">Web/App</option>
                        <option value="Branding">Branding</option>
                        <option value="Product Development">
                          Product Development
                        </option>
                        <option value="Digital Marketing">
                          Digital Marketing
                        </option>
                        <option value="Data Analytic/Data Science">
                          Data Analytic/Data Science
                        </option>
                        <option value="Others">Others</option>
                      </Field>
                      <ErrorMessage
                        name="service_type"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="Contact-field">
                      <Field component="select" name="request_type">
                        <option value="" disabled selected hidden>
                          Request type
                        </option>
                        <option value="Revamp Existing Project">
                          Revamp Existing Project
                        </option>
                        <option value="New Project">New Project</option>
                      </Field>
                      <ErrorMessage
                        name="request_type"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="Contact-field">
                      <div className="Contact-input">
                        <Field
                          component="textarea"
                          name="brief"
                          placeholder="Brief Summary"
                        />
                        <ErrorMessage
                          name="brief"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="button">
                      <button type="submit" disabled={isSubmitting}>
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <div className="Contact-Detail" data-aos="fade-up">
          <div className="Contact-location">
            <h3>VISIT US AT</h3>
            <p>
              7 Alfred Rewane, Ikoyi, <br /> Lagos State, NIGERIA.
            </p>
          </div>
          <div className="Contact-number">
            <h3>CALL US</h3>
            <p>+234-700SPACESERVE</p>
            <h3>Email Us</h3>

            <a href="mailto:t@spaceserve.ng">it@spaceserve.ng</a>
          </div>
        </div>
      </div>
      <Footer dataAos="fade-up" />
    </div>
  );
};
const SuccessScreen = ({ message }) => (
  <div>
    <h1>Success!</h1>
    <p>{message}</p>
  </div>
);
export default Contact;
