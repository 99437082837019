import React from 'react';
import './footer.css';
import whitelogo from '../../assets/whitelogo.png';
import { Link } from 'react-router-dom';

const Footer = ({ dataAos }) => {
  return (
    <div className="sps-footer section_padding">
      <div className="sps-footer-links">
        <div className="sps-footer-logo" data-aos={dataAos}>
          <Link exact to="/">
            <img src={whitelogo} alt="logo" />
          </Link>
        </div>
        <div className="sps-footer-div" data-aos={dataAos}>
          <h4>Company</h4>
          <Link exact to="/aboutus">
            <p>ABOUT US</p>
          </Link>
          <Link to="/contact">
            <p>CONTACT</p>
          </Link>
        </div>
        <div className="sps-footer-div" data-aos={dataAos}>
          <h4>Services</h4>
          <Link to="/services">
            <p>Product Design</p>
          </Link>
          <Link to="/services">
            <p>Software Development</p>
          </Link>
          <Link to="/services">
            <p>Digital Marketing</p>
          </Link>
        </div>
        <div className="sps-footer-div" data-aos={dataAos}>
          <h4>Follow Us On</h4>
          <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
            <p>Facebook</p>
          </a>
          <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
            <p>Instagram</p>
          </a>
          <a href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
            <p>LinkedIn</p>
          </a>
          <a href="https://www.twitter.com/" target="_blank" rel="noreferrer">
            <p>Twitter</p>
          </a>
        </div>
      </div>
      <div className="sps-footer-copyright">
        <p>© 2023 Spaceserve. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
