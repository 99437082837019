import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import design from '../../assets/Services/design.png';
import digital from '../../assets/Services/digital.png';
import software from '../../assets/Services/software.png';
import { Navbar } from '../../Components';
import { Footer } from '../../Container';
import './services.css';

const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 1500, easing: 'ease-in-out-sine' });
  }, []);

  return (
    <div>
      <Navbar />
      <div className="Services section_padding">
        <div className="Services-header">
          <div data-aos="fade-up">
            <h1>Creating Compelling Digital Experiences.</h1>
            <p>
              We adore products. We think that design is an interactive process,
              and that by working together, we can create amazing products.
            </p>
          </div>
          <div className="Services-header-button" data-aos="fade-up">
            <Link to="/contact" className="talk">
              <button>Let's Talk</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="Services-body section_padding">
        <div className="Services-content">
          <div className="Services-content-img" data-aos="fade-up">
            <img src={design} alt="About Image" />
          </div>
          <div className="Services-content-body" data-aos="fade-up">
            <div className="Services-content-title">
              <h4>Product Design</h4>
            </div>
            <div className="Services-content-header">
              <h3>We create enjoyable digital products for users.</h3>
            </div>
            <div className="Services-content-p">
              <p>
                User experience is more than simply a concept to us. We go above
                and above to produce superb data-driven designs that people can
                relate to and enjoy interacting with. Every design choice is
                done with the user in mind from beginning to end.{' '}
              </p>
            </div>
          </div>
        </div>
        <div className="Services-content2">
          <div className="Services-content-body" data-aos="fade-up">
            <div className="Services-content-title">
              <h4>Software Engineering</h4>
            </div>
            <div className="Services-content-header">
              <h3>We create and manage profitable websites and apps.</h3>
            </div>
            <div className="Services-content-p">
              <p>
                Every website or application we develop is intended to support
                businesses in maximizing their online endeavors. We make
                websites that load quickly, are simple to use, and ultimately
                have contemporary looks to go with them.
              </p>
            </div>
          </div>
          <div className="Services-content-img" data-aos="fade-up">
            <img src={software} alt="About Image" />
          </div>
        </div>
        <div className="Services-content3">
          <div className="Services-content-img" data-aos="fade-up">
            <img src={digital} alt="About Image" />
          </div>
          <div className="Services-content-body" data-aos="fade-up">
            <div className="Services-content-title">
              <h4>Digital Marketing</h4>
            </div>
            <div className="Services-content-header">
              <h3>Extend Your Horizons.</h3>
            </div>
            <div className="Services-content-p">
              <p>
                We help your business scale, grow, and reach new heights with
                effectively executed digital marketing strategies and solutions.
                In order to help you develop distinctive and successful
                marketing campaigns to expand your business at a rate you can
                handle, we first introduce you to some of the most effective and
                modern digital marketing technologies utilized by the world's
                most successful organizations.{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer dataAos="fade-up" />
    </div>
  );
};

export default Services;
